
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
        InfoSnackbar: () => import("@/components/layout/InfoSnackbar.vue"),
        Footer: () => import("@/components/layout/Footer2.vue"),
        Overlay: () => import("@/components/layout/Overlay.vue")
    }
})
export default class MainView extends Vue {}
